/**======================================================================
=========================================================================
Template Name: Dashboard Bootstrap 5 Admin Template
Author: Rajodiya Infotech
Support: [support link]
File: style.css
=========================================================================
=================================================================================== */
// theme font
// main framework
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "settings/dark/custom-variables";
@import "settings/dark/theme-variables";
@import "node_modules/bootstrap/scss/bootstrap"; // main framework
@import "themes/generic";
@import "themes/general"; // Layouts
@import "themes/layouts/dash-sidebar";
@import "themes/layouts/dash-header";
@import "themes/layouts/dash-common"; // widget
@import "themes/components/widget"; // Componants
@import "themes/components/avtar";
@import "themes/components/badge";
@import "themes/components/button";
@import "themes/components/dropdown";
@import "themes/components/card";
@import "themes/components/progress";
@import "themes/components/table";
@import "themes/components/tabs";
@import "themes/components/form"; // pages
@import "themes/pages/authentication";
@import "themes/pages/maintaince";
@import "themes/pages/pages";
@import "themes/pages/help-desk";
@import "themes/pages/fullcalender";
@import "themes/pages/icon-lauouts";
@import "themes/pages/user";
@import "themes/pages/e-commerce";
// custom pluginss
@import "themes/plugins/sweatalert";
@import "themes/plugins/lightbox";
@import "themes/plugins/notification";
@import "themes/plugins/pnotify";
@import "themes/plugins/bar-rating";
@import "themes/plugins/modal";
@import "themes/plugins/rangeslider";
@import "themes/plugins/daterange";
@import "themes/plugins/tags-input";
@import "themes/plugins/maxlength";
@import "themes/plugins/wizard";
@import "themes/plugins/select2";
@import "themes/plugins/slider";
@import "themes/plugins/switches";
@import "themes/plugins/data-tables";
@import "themes/plugins/peitychart";

text {
  fill: #efefef;
}

.apexcharts-legend-text {
  color: $body-color !important;
}

.apexcharts-xaxis line {
  stroke: $border-color !important;
}

.apexcharts-gridline,
.apexcharts-xaxis-tick {
  stroke: $border-color;
}

.dash-header {
  .dash-head-link {
    &.active,
    &:active,
    &:focus,
    &:hover {
      background: rgba(255, 255, 255, 0.15);
      color: #fff;
    }
  }
}

.card .dropzone {
  background: darken($card-bg, 5%);
}

.bd-example-modal,
.invoice-total.table {
  background: $body-bg;
}

.task-board-left .task-right-header-revision,
.task-board-left .task-right-header-status,
.task-board-left .task-right-header-users {
  border-color: $border-color;
}

#task-container li,
.fc-button,
.fc-today-button {
  border-color: $border-color;
  background: darken($card-bg, 5%);
  color: $body-color;
}

.fc-state-active,
.fc-state-disabled,
.fc-state-down {
  background: $primary;
  color: $white;
}

.filter-bar .navbar,
.sticky-action .card-header {
  background: $card-bg;
}

.border-light {
  border-color: $border-color !important;
}

.auth-wrapper .auth-side-form,
.auth-wrapper .saprator span,
.dash-wizard-details .nav.nav-tabs .nav-link.active,
.dash-wizard-details .nav.nav-tabs .show > .nav-link,
.sw-theme-default .step-content,
table.DTFC_Cloned tr {
  background: $card-bg;
}

.pct-customizer {
  .pct-c-content {
    color: #293240;

    h6 {
      color: #060606;
    }

    .custom-control-label::before {
      background: #fff;
      border-color: #adb5bd;
    }

    .custom-switch .custom-control-label::after {
      background: #adb5bd;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: #51459d;
      background-color: #51459d;
    }

    .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
      background: #fff;
    }
    .form-check-input:not(:checked) {
      background-color: #fff;
    }
  }
}

.card-footer.bg-light {
  background: darken($card-bg, 3%) !important;
}

.dropdown .dropdown-item svg {
  fill: rgba(155, 158, 162, 0.11);
}

.tab-wizard {
  &.nav {
    .nav-item {
      .nav-link {
        background: transparentize($card-bg, 0.6);
      }
    }

    .nav-link.active,
    .show > .nav-link {
      background: $card-bg;
    }
  }
}

.text-dark {
  color: #d9d9da !important;
}

.uppy-Dashboard-inner,
.uppy-DashboardTab,
.uppy-DragDrop-container {
  background: $card-bg;
  border-color: $border-color;
}

.tox .tox-tbtn {
  color: $body-color;
}

.tox .tox-menubar,
.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
  background: $card-bg;
}

.ql-snow .ql-stroke {
  stroke: $body-color;
}

.ql-snow .ql-picker {
  color: $body-color;
}

.ql-container.ql-snow,
.ql-toolbar.ql-snow,
.trumbowyg-box,
.trumbowyg-editor {
  border-color: $border-color;
}

.note-toolbar.card-header {
  background: #fff;
  border-bottom-color: #ddd;
}

.trumbowyg-button-pane {
  background: $card-bg !important;
}

.trumbowyg-button-pane button.trumbowyg-active,
.trumbowyg-button-pane button:not(.trumbowyg-disable):focus,
.trumbowyg-button-pane button:not(.trumbowyg-disable):hover {
  background: darken($card-bg, 5%) !important;
}

.trumbowyg-box svg,
.trumbowyg-modal svg {
  fill: $body-color !important;
  color: $body-color;
}

.trumbowyg-button-pane .trumbowyg-button-group::after {
  background: $border-color !important;
}

.trumbowyg-button-pane::after {
  background: $border-color !important;
}

.trumbowyg-button-pane {
  border-bottom: 1px solid $border-color !important;
}

button:focus {
  outline: none;
}

.trumbowyg-box,
.trumbowyg-editor {
  border-color: $border-color !important;
}
.card .card-header h5,
.card .card-header .h5 {
  color: $headings-color;
}
.dash-header {
  box-shadow: 0 1px 0 0 #000000;
}
@each $color, $value in $theme-colors {
  .badge.bg-light-#{$color} {
    background: transparentize($value, 0.8);
    color: $value;
    border-color: transparentize($value, 0.8);
  }

  .icon-svg-#{$color} {
    fill: transparentize($value, 0.8);
    stroke: $value;
  }

  .btn-light-#{$color} {
    background: transparentize($value, 0.8);
    border-color: transparentize($value, 0.8);
  }
}

.latest-update-card .card-body .latest-update-box:after {
  background: darken($card-bg, 5%);
}

*[class*="bg-"] {
  text {
    fill: #fff;
  }
}

.close {
  color: $body-color;
  text-shadow: none;

  &:hover {
    color: $body-color;
  }
}
body {
  .title {
    h2 {
      color: $body-color;

      span {
        color: $headings-color;
      }
    }
  }
  .theme-alt-bg,
  .price-section::after {
    background: lighten($card-bg, 3%) !important;
  }
  .faq .accordion .accordion-item .accordion-button:not(.collapsed) {
    box-shadow: 0 6px 30px rgba(6, 6, 6, 0.3) !important;
  }
}
select.form-control {
  background: #{$input-bg} url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke='%239b9ea2' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")
    no-repeat right 0.75rem center/18px 25px;
}

.datepicker-dropdown {
  &:after {
    border-bottom-color: $dropdown-bg;
  }

  &.datepicker-orient-top:after {
    border-top-color: $dropdown-bg;
  }
}

.bootstrap-timepicker-widget {
  .bootstrap-timepicker-hour,
  .bootstrap-timepicker-meridian,
  .bootstrap-timepicker-minute,
  .bootstrap-timepicker-second {
    background: transparent;
  }

  table td a {
    color: $body-color;
  }

  &.timepicker-orient-bottom {
    &:after {
      border-top-color: $dropdown-bg;
    }
  }
}

.select2-dropdown {
  background: $input-bg;
}

.select2-container--default {
  .select2-search--dropdown .select2-search__field {
    background: $input-bg;
    color: $input-color;
  }

  .select2-selection--multiple {
    background: $input-bg;
  }

  .select2-selection--single {
    background: $input-bg;

    .select2-selection__rendered {
      color: $input-color;
    }
  }
}

.irs--flat {
  .irs-line,
  .irs-max,
  .irs-min {
    background: darken($card-bg, 5%);
  }
}

.noUi-target {
  background: darken($card-bg, 5%);
  border-color: darken($card-bg, 6%);
  box-shadow: inset 0 1px 1px darken($card-bg, 8%), 0 3px 6px -5px lighten($card-bg, 5%);
}

.noUi-handle {
  border: 1px solid lighten($card-bg, 7%);
  background: lighten($card-bg, 5%);
  box-shadow: inset 0 0 1px lighten($card-bg, 8%), inset 0 1px 7px lighten($card-bg, 6%),
    0 3px 6px -3px lighten($card-bg, 5%);
}

.noUi-tooltip {
  border-color: $border-color;
  background: $body-bg;
  color: $body-color;
}

.bootstrap-switch {
  .bootstrap-switch-handle-off,
  .bootstrap-switch-handle-on {
    &.bootstrap-switch-default {
      background: lighten($card-bg, 5%);
      color: $body-color;
    }
  }

  .bootstrap-switch-label {
    background: lighten($card-bg, 3%);
  }
}

.tt-menu {
  background: $dropdown-bg;
}

.uppy-DragDrop-arrow {
  fill: $body-color;
}

.sw-theme-arrows,
.sw-theme-circles,
.sw-theme-dots {
  .step-content,
  .sw-toolbar,
  > ul.step-anchor {
    background: transparent;
  }
}

td.highlight {
  background: transparent;
}

.apexcharts-grid-row,
polygon {
  fill: transparent;
}

.apexcharts-svg {
  line {
    stroke: $border-color;
  }

  polygon {
    stroke: lighten($card-bg, 3%);
  }
}

.highcharts-root {
  .highcharts-background {
    fill: transparent;
  }

  .highcharts-grid-line {
    stroke: transparentize($border-color, 0.5);
  }

  .highcharts-title {
    color: $body-color;
    fill: $body-color !important;
  }

  .highcharts-legend-item {
    text {
      fill: $body-color !important;
    }
  }
}

.user-card .user-about-block img {
  box-shadow: 0 0 0 5px $card-bg;
}

.q-view .content {
  background: $card-bg;
}

.hover-blk {
  background: darken($card-bg, 4%);
}

.ticket-block {
  .ticket-type-icon {
    color: $body-color;
  }

  .col.border-right {
    border-color: $border-color !important;
  }

  .hd-body {
    border-color: $border-color;
  }
}

.hd-detail.bg-light,
.topic-name + .bg-light {
  background: darken($card-bg, 2%) !important;
}

.card.background-pattern {
  .bg-white {
    background: darken($card-bg, 4%) !important;
  }
}

.dash-header .dash-cart-menu {
  .drp-cart .cart-head,
  .drp-cart .cart-item.table-responsive {
    background: rgba(255, 255, 255, 0.05);
  }
}

.user-profile-list {
  table tr td {
    background: $card-bg;
  }
}

.user-profile {
  background: $card-bg;
}

.dash-container.sidebar-layouts {
  .dash-content {
    .sidebar-links {
      .nav-item,
      li {
        .nav-link,
        a {
          &.active,
          &:hover {
            background: $body-bg;
            color: $body-color;
            border-color: $body-bg;
          }
        }
      }
    }
  }
}

.bd-example {
}

.highlight {
  background: transparent;

  pre code {
    color: $body-color;
  }
}

code[class*="language-"],
pre[class*="language-"] {
  color: $body-color;

  > code {
    background: $card-bg;
    box-shadow: -1px 0 0 0 #358ccb, 0 0 0 1px $border-color;
  }
}

.token.entity,
.token.operator,
.token.url,
.token.variable {
  background: transparent;
}

.swal2-popup {
  background: lighten($card-bg, 3%) !important;

  .swal2-title {
    color: lighten($body-color, 5%);
  }

  .swal2-content {
    color: $body-color;
  }

  .swal2-footer {
    border-top-color: $border-color;
  }
}

.dash-tab-control.wizard {
  > .steps {
    a {
      background: transparentize($card-bg, 0.5);
    }

    .current a {
      background: $card-bg;
    }
    .done a:hover {
      background: transparentize($card-bg, 0.5);
    }
  }
}

$sidebar-cd-background: #292a33;
$sidebar-cd-color: #fff;
$sidebar-cd-active-color: $primary;
$sidebar-cd-caption: #fff;
$sidebar-cd-shadow: -6.3821px 8.50947px 48.9295px rgba(0, 0, 0, 0.1);
body {
  .dash-sidebar {
    &.light-sidebar {
      background: $sidebar-cd-background;
      &:not(.transprent-bg) {
        box-shadow: $sidebar-cd-shadow;
      }

      .dash-caption {
        color: $sidebar-cd-caption;
      }

      .dash-link {
        color: $sidebar-cd-color;

        .dash-micon {
          background-color: $sidebar-cd-background;
          box-shadow: -3px 4px 23px rgba(0, 0, 0, 0.1);
          i,
          svg {
            color: #8d8fa7;
            stroke: #8d8fa7;
            fill: #f2f2f2;
          }

          .material-icons-two-tone {
            background-color: #525b69;
          }
        }
        .dash-arrow {
          margin-top: 7px;
        }
        &:active,
        &:focus,
        &:hover {
          color: $sidebar-cd-active-color;
        }
      }

      .dash-item.active,
      .dash-item:hover {
        > .dash-link {
          color: $sidebar-cd-active-color;

          .dash-micon i.material-icons-two-tone {
            background-color: $sidebar-cd-active-color;
          }
        }
      }

      .dash-navbar {
        > .dash-item {
          &.active,
          &:focus,
          &:hover {
            > .dash-link {
              box-shadow: -6px 11px 19px rgba(0, 0, 0, 0.04);
              background: $sidebar-cd-background;
              color: $sidebar-cd-color;
            }
          }
        }
      }
    }
  }
  &:not(.minimenu) .dash-sidebar .dash-submenu .dash-item:before {
    border-color: #8d8fa7;
  }
  .dash-header {
    .drp-company .theme-avtar {
      background-color: lighten($sidebar-cd-background, 5%) !important;
    }

    .dash-head-link {
      background: $sidebar-cd-background;
      .dash-h-badge.dots {
        border-color: $sidebar-cd-background;
      }
    }
    &:not(.transprent-bg) {
      background-color: $sidebar-cd-background;
      box-shadow: $sidebar-cd-shadow;
    }
    @media (max-width: 1024px) {
      .header-wrapper,
      &.mob-header-active .header-wrapper {
        background-color: $sidebar-cd-background;
      }
      .dash-mob-drp,
      .dash-mob-drp.mob-drp-active {
        background-color: $sidebar-cd-background;
      }
    }
  }
  .bg-light {
    background: $dark !important;
  }
  .card .card {
    box-shadow: 0 6px 30px rgba(6, 8, 16, 0.3);
  }
  .dataTable-pagination a:hover,
  .dataTable-pagination .active a,
  .dataTable-pagination .active a:focus,
  .dataTable-pagination .active a:hover {
    background: lighten($card-bg, 3%);
  }
  .CodeMirror,
  .editor-toolbar a {
    color: $body-color !important;
  }
  .CodeMirror,
  .accordion-button:not(.collapsed),
  .invoice-total,
  .table thead th {
    background: lighten($card-bg, 3%);
  }
}
