// =======================================
//     List of variables for layout
// =======================================

$header-height: 70px;
$sidebar-width: 255px;
$sidebar-collapsed-width: 70px;

// header
$header-background: #fff;
$header-color: #525b69;
$header-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
$brand-color: #161c25;
$header-item-border-color: #CECECE33;

// Menu
$sidebar-background: #292A33;
$sidebar-color: #fff;
$sidebar-icon-color: #778290;
$sidebar-main-active-color: #fff;
$sidebar-active-color: $primary;
$sidebar-caption: $header-color;
$sidebar-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);

// horizontal menu
$topbar-height: 60px;
$topbar-background: #1c232f;
$topbar-color: #b5bdca;
$header-submenu-background: #fff;
$header-submenu-color: #1c232f;


// card block
$card-shadow:  none;

$soft-bg-level: -80%;

$themes-colors: (
    theme-1: (
        body: linear-gradient(141.55deg, #22242C 3.46%, #22242C 99.86%),
        primary: #0CAF60,
    primary-grd:
      linear-gradient(141.55deg, #0CAF60 3.46%, #0CAF60 99.86%),
    ),
    theme-2: (
        body: linear-gradient(141.55deg, #22242C 3.46%, #22242C 99.86%),
        primary: #6FD943,
    primary-grd:
      linear-gradient(141.55deg, #6FD943 3.46%, #6FD943 99.86%),
    ),
    theme-3: (
        body: linear-gradient(141.55deg, #22242C 3.46%, #22242C 99.86%),
        primary: #584ED2,
        primary-grd: linear-gradient(141.55deg, #584ED2 3.46%, #584ED2 99.86%),
    ),
    theme-4: (
        body: linear-gradient(141.55deg, #22242C 3.46%, #22242C 99.86%),
        primary: #145388,
    primary-grd:linear-gradient(141.55deg, #145388 3.46%, #145388 99.86%),
    ),
    theme-5: (
        body: linear-gradient(141.55deg, #22242C 3.46%, #22242C 99.86%),
        primary: #B9406B,
    primary-grd: linear-gradient(141.55deg, #B9406B 3.46%, #B9406B 99.86%),
    ),
    theme-6: (
        body: linear-gradient(141.55deg, #22242C 3.46%, #22242C 99.86%),
        primary: #008ECC,
        primary-grd:linear-gradient(141.55deg, #008ECC 3.46%, #008ECC 99.86%),
    ),
    theme-7: (
        body: linear-gradient(141.55deg, #22242C 3.46%, #22242C 99.86%),
        primary: #922C88,
        primary-grd:linear-gradient(141.55deg, #922C88 3.46%, #922C88 99.86%),
    ),
    theme-8: (
        body: linear-gradient(141.55deg, #22242C 3.46%, #22242C 99.86%),
        primary: #C0A145,
    primary-grd:linear-gradient(141.55deg, #C0A145 3.46%, #C0A145 99.86%),
    ),
    theme-9: (
        body: linear-gradient(141.55deg, #22242C 3.46%, #22242C 99.86%),
        primary: #48494B,
        primary-grd:linear-gradient(141.55deg, #48494B 3.46%, #48494B 99.86%),
    ),
    theme-10: (
        body: linear-gradient(141.55deg, #22242C 3.46%, #22242C 99.86%),
        primary: #0C7785,
        primary-grd:linear-gradient(141.55deg, #0C7785 3.46%, #0C7785 99.86%),
    ),
);